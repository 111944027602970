<template>
    <b-nav-item-dropdown
        class="help-menu"
        id="topo-ajuda"
        right
        ref="navdropdown"
    >
        <template #button-content>
            <i class="fa fa-question-circle"></i>
        </template>
        <li 
            class="header"
        >
            Ajuda
        </li>
        <li>
            <ul class="menu">
                <li 
                    v-for="topico in topicos" 
                    :key="topico.nome"
                    :style="$app.ocultar_tutorial == 1 && topico.link == '/ajuda/videos_tutoriais' ? 'display: none;' : ''"
                >
                    <a 
                        :href="topico.link" 
                        :target="topico.target"
                        @click="$refs.navdropdown.hide()"
                    >
                        <h3>
                            <div>
                                <i :class="topico.icone"></i> 
                                {{ topico.nome }}
                            </div>
                            <span 
                                class = "text-muted"
                            >
                                {{ topico.descricao }}
                            </span>
                        </h3>
                    </a>
                </li>
            </ul>
        </li>
    </b-nav-item-dropdown>
</template>
<script>
export default {
    data(){
        return {
            topicos: [
                {
                    nome: 'Central de ajuda', 
                    icone: 'fa fa fa-list-alt', 
                    descricao: 'Encontre aqui soluções e respostas rápidas', 
                    link: '/ajuda',
                    target: '_self'
                },
                {
                    nome: 'Vídeos tutoriais', 
                    icone: 'fa fa-caret-square-o-right', 
                    descricao: 'Aprenda sobre os recursos do sistema', 
                    link: '/ajuda/videos_tutoriais',
                    target: '_self'
                },
                // {
                //     nome: 'Atualizações', 
                //     icone: 'fa fa-refresh', 
                //     descricao: 'Confira o que há de novo no ' + this.$app.nome_divulgacao, 
                //     link: '/atualizacoes',
                //     target: '_self'
                // },
                {
                    nome: 'Portal de ideias', 
                    icone: 'fa fa-lightbulb-o', 
                    descricao: 'Colabore com novas ideias para a evolução do ' + this.$app.nome_divulgacao, 
                    link: '/ideias',
                    target: '_self'
                },
                {
                    nome: 'Aplicativo Desktop', 
                    icone: 'fa icon-font icon-battery', 
                    descricao: 'Aplicativo para emissão de notas com certificado A3', 
                    link: this.$usuarioLogado.aplicativo_desktop,
                    target: '_blank'
                },
                {
                    nome: 'Suporte remoto', 
                    icone: 'fa icon-sync', 
                    descricao: 'Acessamos seu computador pela Internet', 
                    link: this.$usuarioLogado.suporte_remoto,
                    target: '_blank'
                }
            ]
        }
    }
}
</script>