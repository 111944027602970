import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { formataUrlBase, validaSite } from '@/shared/common/functions/roteamento'
import validaAutenticacao from '@/shared/common/functions/autenticacao'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({
            behavior: "smooth"
        });
    }
})

router.beforeEach(async (to, from, next) => {
    formataUrlBase(to.fullPath)
    const possuiSite = await validaSite(to)
    if(!possuiSite){
        validaAutenticacao(to, next)
    }
})

export default router
