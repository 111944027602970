const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarContratosServicos',
                path: '/contratos_servicos',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarContratosServicos',
                path: '/contratos_servicos/adicionar',
                // component: () => import('../views/Adicionar'),
                component: require('../views/Adicionar').default,
                meta: {
                    fullScreen: true
                },
            },
            {
                name: 'renovarContratosServicos',
                path: '/contratos_servicos/adicionar/:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                },
            },
            {
                name: 'editarContratosServicos',
                path: '/contratos_servicos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                },
            },
            {
                name: 'visualizarContratosServicos',
                path: '/contratos_servicos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    },
    {
        name: 'impressaoContratoServico',
        path: '/contratos_servicos/imprimir_pdf/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]

export default routes
