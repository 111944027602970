const Interno = () => import('@/shared/components/layout/Interno')
const ListarNcms = () => import('@/domains/notas_fiscais/auxiliares/ncm/views/Listar')
const AdicionarNcm = () => import('@/domains/notas_fiscais/auxiliares/ncm/views/Adicionar')
const EditarNcm = () => import('@/domains/notas_fiscais/auxiliares/ncm/views/Editar')
const VisualizarNcm = () => import('@/domains/notas_fiscais/auxiliares/ncm/views/Visualizar')
const ImportarNcm = () => import('@/domains/notas_fiscais/auxiliares/ncm/views/Importar')

const routes = [
    {
        path: '/ncm',
        component: Interno,
        children: [
            {
                name: 'listarNcms',
                path: '/',
                component: ListarNcms,
            },
            {
                name: 'adicionarNcm',
                path: '/ncm/adicionar',
                component: AdicionarNcm,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarNcm',
                path: '/ncm/editar/:id',
                component: EditarNcm,
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'VisualizarNcms',
                path: '/ncm/visualizar/:id',
                component: VisualizarNcm,
            },
            {
                name: 'ImportarNcms',
                path: '/ncm/importar_ncm',
                component: ImportarNcm,
            }
        ]
    },
]

export default routes
