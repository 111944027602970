import Vue from 'vue'
import { somenteNumeros, somenteNumerosVirgula , somenteNumerosVirgulaPonto} from './global'

Vue.directive('mascara-numero', {
    inserted: function (el, binding) {
        el.addEventListener('keypress', function( e ) {
            return somenteNumeros(e) 
        }, true)
        el.style.textAlign = binding.value || 'right'
    }
})
Vue.directive('mascara-numero-virgula', {
    inserted: function (el, binding) {
        el.addEventListener('keypress', function( e ) {
            return somenteNumerosVirgula(e) 
        }, true)
        el.style.textAlign = binding.value || 'left'
    }
})
Vue.directive('mascara-numero-virgula-ponto', {
    inserted: function (el, binding) {
        el.addEventListener('keypress', function( e ) {
            return somenteNumerosVirgulaPonto(e)
        }, true)
        el.style.textAlign = binding.value || 'left'
    }
})