<template>
    <b-modal 
        v-show="false" 
        v-model="show"
        :headerClass="headerClass"
        :bodyClass="bodyClass"
        :noCloseOnEsc="noCloseOnEsc"
        :noCloseOnBackdrop="noCloseOnBackdrop"
        @hide="close(false)"
    >
        <template #modal-header="{ close }">
            <h5 
                v-if="
                    title || icon
                " 
                class="modal-title text-center" 
                :class="titleClass"
            >
                <i 
                    v-if="icon" 
                    :class="icon" 
                    class="modal-title-icon"
                />
                {{ title }}
            </h5>
            <button 
                v-if="title"
                type="button" 
                aria-label="Close" 
                class="close"
                @click="close()"
            >
                ×
            </button>
        </template>
        <template>
            <div 
                :class="bodyClassContent" 
                v-html="message"
            />
        </template>
        <template #modal-footer>
            <b-button v-for="button in buttons" 
                :key="button.name"
                :variant="button.variant"
                :value="button.value"
                @click="close(button.value)"
            >
                <i v-if="button.icon" :class="button.icon" /> {{ button.name }}
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import Vue from 'vue'
export default {
    data(){
        return {
            message: '',
            show: false,
            title: null,
            titleClass: null,
            icon: null,
            headerClass: 'text-center border-0 pb-0 flex-column align-items-center',
            bodyClass: 'h5 pt-0 text-center',
            bodyClassContent: 'text-center',
            noCloseOnEsc: false,
            noCloseOnBackdrop: false,
            buttons:[
                {
                    name: 'OK',
                    value: true,
                    variant: 'primary'
                }
            ],
            resolve: undefined,
            reject: undefined,
        }
    },
    mounted(){
        Vue.prototype.$dialog = {}
        const methods = [
            'success',  'error', 'warning', 'confirm', 'delete'
        ]
        methods.forEach(method => {
            Vue.prototype.$dialog[method] = this[method]
        })
    },
    methods:{
        reset: function() {
            Object.assign(this.$data, this.$options.data())
        },
        merge(options = {}, defaultOptions = {}){
            options = Object.assign(defaultOptions, options)
            return options
        },
        open(message, options = {}){
            this.reset()
            this.show = true
            this.message = message
            for (let data in options) {
                this[data] = options[data];
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        close(value){
            this.show = false
            if (typeof this.resolve == 'function') {
                this.resolve(value)
            }

            document.removeEventListener('keyup', this.enter)
        },
        success(message, options = {}){
            const defaultOptions = {}
            defaultOptions['titleClass'] = 'text-success'
            defaultOptions['icon'] = 'glyphicon glyphicon-ok'
            options = this.merge(options, defaultOptions)
            return this.open(message, options)
        },
        error(message, options = {}){
            const defaultOptions = {}
            defaultOptions['titleClass'] = 'text-danger'
            defaultOptions['icon'] = 'glyphicon glyphicon-remove'
            options = this.merge(options, defaultOptions)
            return this.open(message, options)
        },
        warning(message, options = {}){
            const defaultOptions = {}
            defaultOptions['titleClass'] = 'text-warning'
            defaultOptions['icon'] = 'glyphicon glyphicon-warning-sign'
            options = this.merge(options, defaultOptions)
            return this.open(message, options)
        },
        confirm(message, options = {}){
            const defaultOptions = {}
            defaultOptions['titleClass'] = 'text-primary'
            defaultOptions['icon'] = 'glyphicon glyphicon-question-sign'
            defaultOptions['buttons'] = [
                {
                    name: 'Não',
                    value: false,
                    variant: 'secondary'
                },
                {
                    name: 'Sim',
                    value: true,
                    variant: 'primary'
                }
            ]
            
            setTimeout(() => {
                document.addEventListener('keyup', this.enter)
            }, 300)

            options = this.merge(options, defaultOptions)
            return this.open(message, options)
        },
        delete(message, options = {}){
            const defaultOptions = {}
            defaultOptions['titleClass'] = 'text-danger'
            defaultOptions['icon'] = 'glyphicon glyphicon-trash'
            defaultOptions['buttons'] = [
                {
                    name: 'Não',
                    value: false,
                    variant: 'secondary'
                },
                {
                    name: 'Sim',
                    value: true,
                    variant: 'primary'
                }
            ]
            options = this.merge(options, defaultOptions)
            return this.open(message, options)
        },
        enter (e) {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                this.close(true)
            }
        }
    }
}
</script>
<style>
    .modal-title-icon{
        font-size: 60px
    }
</style>