<template>
    <b-nav-item-dropdown
        class="help-menu"
        id="topo-configuracoes"
        right
        ref="navdropdown"
    >
        <template #button-content>
            <i class="fa fa-cogs"></i>
        </template>
        <li class="header">
            Configurações
        </li>
        <li>
            <ul 
                class="menu"
            >
                <li 
                    v-for="topico in topicos" 
                    :key="topico.nome" 
                    v-show="topico.habilitado"
                >
                    <a 
                        :href="topico.link"
                        @click="$refs.navdropdown.hide()"
                    >
                        <h3>
                            <div>
                                <i 
                                    :class="topico.icone"
                                ></i> 
                                {{ topico.nome }}
                            </div>
                        </h3>
                    </a>
                </li>
            </ul>
        </li>
    </b-nav-item-dropdown>
</template>
<script>
export default {
    data(){
        return {
            topicos: [
                {
                    nome: 'Gerais', 
                    icone: 'fa fa-gear', 
                    link: '/configuracoes/gerais',
                    habilitado: true,
                },
                {
                    nome: 'Meu plano', 
                    icone: 'fa fa-star-half-o', 
                    link: '/planos/meu_plano',
                    habilitado: (this.$usuarioLogado.Empresa.tipo_cobranca != 'P' ? true : false),
                },
                {
                    nome: 'Aplicativos', 
                    icone: 'fa fa icon-sync', 
                    link: '/integracoes',
                    habilitado: true,
                },
                {
                    nome: 'Domínio próprio', 
                    icone: 'fa fa-globe', 
                    link:  `/dominios/configurar`,
                    habilitado: true,
                },
                {
                    nome: 'Meu contador', 
                    icone: 'fa icon-user', 
                    link: `/contador`,
                    habilitado: (this.$usuarioLogado.Empresa.tipo != 'C' ? true : false),
                },
                {
                    nome: 'Marca da empresa', 
                    icone: 'fa icon-images-gallery', 
                    link: `/configuracoes/marca_empresa`,
                    habilitado: (this.$usuarioLogado.Empresa.tipo == 'C' ? true : false),
                },
                {
                    nome: 'Backup de dados', 
                    icone: 'fa fa-hdd-o', 
                    link: '/backup_dados',
                    habilitado: (this.$usuarioLogado.master == 1 ? true : false),
                },
                {
                    nome: 'Exclusão de dados', 
                    icone: 'fa fa-trash-o', 
                    link: '/exclusao_dados',
                    habilitado: (this.$usuarioLogado.master == 1 ? true : false),
                }
            ]
        }
    }
}
</script>
