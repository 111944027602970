import Vue from 'vue'
import $api from '@/shared/services/api'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

const TOKEN_ID = 'x-token-auth'
const VERSAO_APP = 'version-app'

export default {
    namespaced: true,
    state: {
        token: Vue.$cookies.get(TOKEN_ID),
        versaoApp: Vue.$cookies.get(VERSAO_APP),
        usuarioLogado: null,
    },
    getters: {
        pegaUsuarioLogado(state){
            return state.usuarioLogado
        },
        pegaToken(state){
            return state.token
        },
    },
    mutations: {
        atualizaToken(state, token){
            state.token = token
        },
        atualizaVersaoApp(state, versaoApp){
            state.versaoApp = versaoApp
        },
        atualizaUsuarioLogado(state, usuarioLogado){
            state.usuarioLogado = usuarioLogado
        },
    },
    actions: {
        salvaToken({ commit }, data){
            Vue.$cookies.set(TOKEN_ID, data.token, '30d', null, data.url_token || null)
            commit('atualizaToken', data.token)
            Vue.$cookies.set(VERSAO_APP, data.versao_app, null, null, data.url_token || null)
            commit('atualizaVersaoApp', data.versao_app)
        },
        async login({ dispatch }, dados = []){
            const login = await $api.cPost(
                Vue.prototype.$urlBase
                + '/usuarios/login', 
                dados
            )
            if(login.status == 'success'){
                dispatch('salvaToken', login.data)
            }
            return login
        },
        async logout({ commit, dispatch }){
            const logout = await $api.cGet(
                Vue.prototype.$urlBase
                + '/usuarios/logout'
            )
            if(logout.status == 'success'){
                dispatch('salvaToken', logout.data)
                commit('usuarioLogado', [])
            }
            return logout
        },
        async carregaUsuarioLogado({ commit }){
            const autenticacao = await $api.cGet(
                Vue.prototype.$urlBase
                + '/usuarios/verifica_autenticacao', 
                {}, 
                false
            )
            if(autenticacao.status == 'success'){
                commit('atualizaUsuarioLogado', autenticacao.data)
            }
            return autenticacao
        }
    },
}
