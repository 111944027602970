import Vue from 'vue'
import store from '@/shared/store'
import loading from '@/shared/plugins/loading'

export const formataUrlBase = ((rota) => {
    if(Vue.prototype.$urlBase == undefined){
        Vue.prototype.$urlBase = ''
    }
    Vue.prototype.$url = {
        module: '',
        controller: '',
        action: '',
    }
    const modules = require('@/modules/modules.json')
    const p1 = rota.split('?')
    const p2 = p1[0].split('/')
    let i = 1
    if(Array.isArray(modules)){
        modules.forEach(module => {
            if(p2[i] && p2[i] == module){
                const externalModules = require('@/modules/external-modules.json')
                Vue.prototype.$url.module = module
                if(externalModules.filter(item => item == module).length == 0) {
                    Vue.prototype.$urlBase = '/' + module
                }
                i++
            } 
        })  
    }
    if(p2[i]){
        Vue.prototype.$url.controller = p2[i]   
        i++   
    }
    if(p2[i]){
        Vue.prototype.$url.action = p2[i]   
        i++   
    }
})

export const formataRotasGlobais = ((modulo, rotas) => {
    rotas.forEach((rota, i) => {
        if (rota.path) {
            rotas[i]['path'] = (modulo ? '/' + modulo : '') + rota.path
        }
        if(rota.alias){
            if(typeof rota.alias == 'object'){
                rota.alias.forEach((rAlias, rI) => {
                    rotas[i]['alias'][rI] = (modulo ? '/' + modulo : '') + rAlias
                })
            }
            if(typeof rota.alias == 'string'){
                rotas[i]['alias'] = (modulo ? '/' + modulo : '') + rota.alias
            }
            
        }
        if (rota.children) {
            rota.children.forEach((cRota, cI) => {
                if (cRota.path) {
                    rotas[i]['children'][cI]['path'] = (modulo ? '/' + modulo : '') + cRota.path
                }
                if(cRota.alias){
                    if(typeof cRota.alias == 'object'){
                        cRota.alias.forEach((cAlias, cAI) => {
                            rotas[i]['children'][cI]['alias'][cAI] = (modulo ? '/' + modulo : '') + cAlias
                        })
                    }
                    if(typeof cRota.alias == 'string'){
                        rotas[i]['children'][cI]['alias'] = (modulo ? '/' + modulo : '') + cRota.alias
                    }
                }
                if (cRota.redirect) {
                    rotas[i]['children'][cI]['redirect'] = (modulo ? '/' + modulo : '') + cRota.redirect
                }
            })
        }
    })
    return rotas
})

export const formataUrlRedirecionamento = ((redirecionamento) => {
    let retorno = '/'
    if(redirecionamento.constructor.name === 'String'){
        retorno = redirecionamento
    }else{
        retorno = Vue.prototype.$urlBase + '/'
        retorno += redirecionamento.controller || Vue.prototype.$url.controller
        retorno += '/'
        retorno += redirecionamento.action || Vue.prototype.$url.action
        Object.keys(redirecionamento).map(parametro => {
            if(
                !['plugin', 'controller', 'action', '?'].includes(parametro)
                &&
                isNaN(parametro)
            ){
                retorno += '/'
                retorno += parametro
                retorno += ':'
                retorno += redirecionamento[parametro]
            }
        })
        if(redirecionamento[0]){
            retorno += '/'
            retorno += redirecionamento[0]
        }
        if(redirecionamento['?']){
            retorno += '?'
            Object.keys(redirecionamento['?']).map((parametro, i) => {
                if(i > 0){
                    retorno += '&'
                }
                retorno += parametro 
                retorno += '=' 
                retorno += redirecionamento['?'][parametro]
            })
        }
    }
    retorno = retorno.replace(window.location.origin, '')
    console.log(retorno)
    return retorno
})

export const validaSite = async (to) => {
    if(to.path == '' || to.path == '/'){
        await store._modules.root.context.dispatch(
            'dominios/carregaDominio'
        ).then((retorno) => {
            if(retorno.status == 'success'){
                if(retorno.data.Dominio.possui_site == 1){
                    loading.show()
                    window.location.href = '/site'
                    return true
                }else{
                    window.location.href = '/inicio'
                    return false
                }
            }else{
                return false
            }
        })
    }else{
        return false
    }
}

// export const importaRotas = ((caminho = '../') => {
//     const rotas = []
//     const caminhos = require.context(caminho, true, /routes.js$/i)
//     const contexto = caminhos.keys().map(caminhos).map(m => m.default)
//     contexto.map((arr) => {
//         if(arr) {
//             arr.map((val) => {
//                 rotas.push(val)
//             })
//         }
//     })
//     return rotas
// })