const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarDevolucoesDeLocacoes',
                path: '/locacoes/devolucoes',
                component: () => import('../views/Listar')
            },
            {
                name: 'visualizarDevolucoesDeLocacoes',
                path: '/locacoes/visualizar_devolucao/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    },
]

export default routes