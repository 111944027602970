const routes = [
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarVendasProdutos',
                path: '/vendas_produtos',
                alias: '/vendas_produtos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarVendaProduto',
                path: '/vendas_produtos/adicionar',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'editarVendaProduto',
                path: '/vendas_produtos/editar/:id',
                component: () => import('../views/Editar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'visualizarVendaProduto',
                path: '/vendas_produtos/visualizar/:id',
                component: () => import('../views/Visualizar'),
                meta: {
                    tipoVisualizacao: 'orcamento'
                }
            },
            {
                name: 'agruparVendaProduto',
                path: '/vendas_produtos/agrupar_vendas',
                component: () => import('../views/Agrupar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'copiarVendaProduto',
                path: '/vendas_produtos/adicionar/:id',
                component: () => import('../views/Adicionar'),
                meta: {
                    fullScreen: true
                }
            },
            {
                name: 'faturamentoParcialVendaProduto',
                path: '/vendas_produtos/faturamento_parcial/:id',
                component: () => import('../views/FaturarParcialmente'),
                meta: {
                    fullScreen: true
                }
            }
        ]
    },
    {
        name: 'impressaoVendaProduto',
        path: '/venda/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'cupomPresenteHashVendaProduto',
        path: '/vendas_cupom_presente/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    },
    {
        name: 'impressaoOrdemProducao',
        path: '/vendas_produtos/imprimir_ordem_producao/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        }
    }
]

export default routes
