const routes = [
    {
        path: '/notas_fiscais_servicos/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarNotasFiscaisServicos',
                path: '/notas_fiscais_servicos',
                alias: '/notas_fiscais_servicos/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarNotaFiscalServico',
                path: '/notas_fiscais_servicos/adicionar',
                component: () => import('../views/Adicionar'),
                meta: { fullScreen: true }
            },
            {
                name: 'adicionarHashNotaFiscalServico',
                path: '/notas_fiscais_servicos/adicionar/:hash',
                component: () => import('../views/Adicionar'),
                meta: { fullScreen: true }
            },
            {
                name: 'editarNotaFiscalServico',
                path: '/notas_fiscais_servicos/editar/:id',
                component: () => import('../views/Editar'),
                meta: { fullScreen: true }
            },
            {
                name: 'visualizarNotaFiscalServico',
                path: '/notas_fiscais_servicos/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
        ]
    },
    {
        name: 'enviarNotaFiscalServico',
        path: '/notas_fiscais_servicos/enviar/:id',
        component: () => import('../components/Envio')
    },
    {
        name: 'danfeNotaFiscalServico',
        path: '/nfse/danfe/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            public: true
        },
    },
    {
        name: 'xmlNotaFiscalServico',
        path: '/nfse/xml/:id',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
    {
        name: 'visualizarXmlNotaFiscalServico',
        path: '/notas_fiscais_servicos/enviar_nota_fiscal/:id',
        component: () => import('../../shared/components/Lista/VisualizacaoXml'),
        meta: {
            public: true
        },
    },
]

export default routes
