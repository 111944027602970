const routes = [
    {
        path: '/notas_fiscais_consumidores/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarNotasFiscaisConsumidores',
                path: '/notas_fiscais_consumidores',
                alias: ['/notas_fiscais_consumidores/index'],
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarNotaFiscalConsumidor',
                path: '/notas_fiscais_consumidores/adicionar',
                component: () => import('../views/Adicionar'),
                meta: { fullScreen: true }
            },
            {
                name: 'adicionarVendaNotaFiscalConsumidor',
                path: '/notas_fiscais_consumidores/adicionar/:hash',
                component: () => import('../views/Adicionar'),
                meta: { fullScreen: true }
            },
            {
                name: 'editarVendaNotaFiscalConsumidor',
                path: '/notas_fiscais_consumidores/editar/:id',
                component: () => import('../views/Editar'),
                meta: { fullScreen: true }
            },
            {
                name: 'visualizarVendaNotaFiscalConsumidor',
                path: '/notas_fiscais_consumidores/visualizar/:id',
                component: () => import('../views/Visualizar')
            },
            {
                name: 'inutilizacoes_numeracoes',
                path: '/notas_fiscais_consumidores/listar_inutilizacoes_numeracoes',
                alias: '/notas_fiscais_consumidores/listar_inutilizacoes_numeracoes/index',
                component: () => import('../views/InutilizacoesNumeracoes')
            },
        ]
    },
    {
        name: 'impressaoNfceHashNotasFiscaisConsumidores',
        path: '/nfc/danfe/:hash',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'impressaoNfceIdNotasFiscaisConsumidores',
        path: '/notas_fiscais_consumidores/imprimir_danfe/:id',
        component: () => import('@/shared/components/ui/Impressao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
    {
        name: 'baixarXmlIdNotasFiscaisConsumidores',
        path: '/nfc/xml/:hash',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
    {
        name: 'baixarXmlHashNotasFiscaisConsumidores',
        path: '/notas_fiscais_consumidores/baixar_xml/:id',
        component: () => import('@/shared/components/ui/Download'),
        meta: {
            public: true
        },
    },
    {
        name: 'visualizarXml',
        path: '/notas_fiscais_consumidores/enviar_nota_fiscal/:id',
        component: () => import('../../shared/components/Lista/VisualizacaoXml'),
        meta: {
            public: true
        },
    },
]

export default routes
