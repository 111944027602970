const routes = [
    {
        name: 'visualizarPedido',
        path: '/pedidos/visualizar/:id',
        component: () => import('@/shared/components/ui/Visualizacao'),
        meta: {
            fullScreen: true,
            public: true
        },
    },
]
export default routes
