import Vue from 'vue'
import { salvaMensagem } from '@/shared/common/functions/mensagens'
import loading from '@/shared/plugins/loading'
import store from '@/shared/store'
export default function validaAutenticacao(to, next) {
    if(!to.meta.public){
        loading.show()
        store._modules.root.context.dispatch('usuarios/carregaUsuarioLogado').then((autenticacao) => {
            loading.hide()
            if((autenticacao && autenticacao.status == 'error')){
                if(autenticacao.message){
                    salvaMensagem(autenticacao.message, 'Autenticação', 'error')
                }
                location.href 
                    = Vue.prototype.$urlBase 
                    + '/login?retorno=' 
                    + to.fullPath
            }else{
                next()
            }
            loading.hide()
        })
    }else{
        next()
    }
}