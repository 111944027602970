
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import dictionary from './dictionary'
//import Validator from './mixins/Validator'
import './cpf-cnpj'
import './numero'
import './email'
import './data'
import './cep'

Vue.use(VeeValidate, {
    events: 'blur|change',
    immediate: true,
    classes: true,
    classNames: {
        invalid: 'error'
    },
    locale: 'pt',
    dictionary: dictionary,
    fieldsBagName: 'veeFields',

    inject: false
})

import { ValidationProvider, ValidationObserver } from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
//Vue.mixin(Validator)
